import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/modules/login/stores/auth';
import { useChatOptions } from '@/modules/chat/plugins/options';
import { useFeedbackOptions } from '@/modules/feedback/plugins/options';
import { useQuestionOptions } from '@/modules/questions/plugins/options';
import { usePlayerOptions } from '@/modules/player/plugins/options';
import { useLoginOptions } from '@/modules/login/plugins/options';
import { useInteractionOptions } from '@/modules/interaction/plugins/options';
import { useLiveOptions } from '@/modules/live/plugins/options';
import { useModpanelOptions } from '@/modules/modpanel/plugins/options';
import { usePresenterOptions } from '@/modules/presenter/plugins/options';
import { useAppOptions } from '@/options/app';
import { useCookieOptions } from '@/options/cookies';
import { storeToRefs } from 'pinia';
import { hasToken } from '@/helper/token';
import { useAuditOptions } from '@/modules/audit/plugins/options';
import { useSupportOptions } from '@/modules/support/plugins/options';
import { useNotesOptions } from '@/modules/notes/plugins/options';
import { useUserTagsOptions } from '@/modules/tags/plugins/options';
import {useActionOptions} from "@/modules/actions/plugins/options";

export function createGuardedRouter() {
  let authStore = useAuthStore();
  let { authenticated, isAdmin, isStage, isModeration, isLiveUser, isNone } =
    storeToRefs(authStore);
  let { loadUser } = authStore;

  let options = useAppOptions();
  let cookieOptions = useCookieOptions();

  const router = createRouter({
    history: createWebHistory(options.baseUrl),
    routes: [
      {
        path: '/landing',
        name: 'landing',
        component: () => import('../LandingView.vue'),
        meta: {
          guards: ['guest'],
        },
      },
      {
        path: '/',
        name: 'view',
        component: () => import('../modules/player/views/MainView.vue'),
        meta: {
          guards: ['authenticated', 'mainViewEnabled'],
        },
      },
      {
        path: '/unverified',
        name: 'unverified',
        component: () => import('../modules/login/views/UnverifiedView.vue'),
        meta: {
          guards: ['unverified'],
        },
      },
      {
        path: '/player',
        name: 'player',
        component: () => import('../modules/player/views/PlayerView.vue'),
        meta: {
          guards: ['authenticated'],
        },
      },
      {
        path: '/audio',
        name: 'audioplayer',
        component: () => import('../modules/player/views/AudioPlayerView.vue'),
        meta: {
          guards: ['authenticated'],
        },
      },
      {
        path: '/chat',
        name: 'chat',
        component: () => import('../modules/chat/views/ChatView.vue'),
        meta: {
          guards: ['authenticated', 'chatEnabled'],
        },
      },
      {
        path: '/questions',
        name: 'questions',
        component: () => import('../modules/questions/views/QuestionView.vue'),
        meta: {
          guards: ['authenticated', 'questionsEnabled'],
        },
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: () => import('../modules/feedback/views/FeedbackView.vue'),
        meta: {
          guards: ['authenticated', 'feedbackEnabled'],
        },
      },
      {
        path: '/interactions',
        name: 'interactions',
        component: () => import('../modules/interaction/views/InteractionsView.vue'),
        meta: {
          guards: ['moderation', 'interactionsEnabled'],
        },
      },
      {
        path: '/presentation',
        name: 'presentation',
        component: () => import('../modules/presenter/views/PresenterView.vue'),
        meta: {
          guards: ['authenticated'], //TODO
        },
      },
      {
        path: '/qr',
        name: 'qr',
        component: () => import('../modules/login/views/QRCodeView.vue'),
        meta: {
          guards: ['authenticated', 'qrCodeEnabled'],
        },
      },
      {
        path: '/qr/:token',
        name: 'qrlogin',
        component: () => import('../modules/login/views/QRCodeLoginView.vue'),
        meta: {
          guards: ['qrCodeEnabled'],
        },
      },
      {
        path: '/notes',
        name: 'notes',
        component: () => import('../modules/notes/views/NotesView.vue'),
        meta: {
          guards: ['authenticated', 'notesEnabled'],
        },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../modules/login/views/LoginView.vue'),
        meta: {
          guards: ['guest', 'loginEnabled'],
        },
      },
      {
        path: '/login/magic/:id/:hash/:token',
        name: 'magicLogin',
        component: () => import('../modules/login/views/MagicLoginView.vue'),
        meta: {
          guards: ['guest', 'magicLoginEnabled'],
        },
      },
      {
        path: '/dashboard/audit',
        name: 'auditcontrol',
        component: () => import('../modules/audit/views/AdminAuditView.vue'),
        meta: {
          guards: ['admin', 'auditEnabled'],
        },
      },
      {
        path: '/dashboard/users',
        name: 'usercontrol',
        component: () => import('../modules/users/views/AdminUserView.vue'),
        meta: {
          guards: ['admin'],
        },
      },
      {
        path: '/dashboard/users/tags',
        name: 'usertagscontrol',
        component: () => import('../modules/tags/views/AdminTagView.vue'),
        meta: {
          guards: ['admin', 'userTagsEnabled'],
        },
      },
      {
        path: '/dashboard/live',
        name: 'livecontrol',
        component: () => import('../modules/live/views/AdminLiveView.vue'),
        meta: {
          guards: ['stage'],
        },
      },
      {
        path: '/dashboard/chat',
        name: 'chatcontrol',
        component: () => import('../modules/chat/views/AdminChatView.vue'),
        meta: {
          guards: [useChatOptions().moderation ? 'moderation' : 'stage', 'chatEnabled'],
        },
      },
      {
        path: '/dashboard/questions',
        name: 'questioncontrol',
        component: () => import('../modules/questions/views/AdminQuestionsView.vue'),
        meta: {
          guards: [useQuestionOptions().moderation ? 'moderation' : 'stage', 'questionsEnabled'],
        },
      },
      {
        path: '/dashboard/feedback',
        name: 'feedbackcontrol',
        component: () => import('../modules/feedback/views/AdminFeedbackView.vue'),
        meta: {
          guards: [useFeedbackOptions().moderation ? 'moderation' : 'stage', 'feedbackEnabled'],
        },
      },
      {
        path: '/dashboard/modpanel',
        name: 'modpanelcontrol',
        component: () => import('../modules/login/views/AdminModPanelView.vue'),
        meta: {
          guards: [useModpanelOptions().moderation ? 'moderation' : 'stage', 'modPanelEnabled'],
        },
      },
      {
        path: '/dashboard/interactions',
        name: 'interactioncontrol',
        component: () => import('../modules/interaction/views/AdminInteractionView.vue'),
        meta: {
          guards: [useInteractionOptions().moderation ? 'moderation' : 'stage', 'liveEnabled'],
        },
      },
      {
        path: '/dashboard/presenter',
        name: 'presentercontrol',
        component: () => import('../modules/presenter/views/AdminPresenterView.vue'),
        meta: {
          guards: [usePresenterOptions().moderation ? 'moderation' : 'stage', 'presenterEnabled'],
        },
      },
      {
        path: '/dashboard/presenter/:id',
        name: 'presenterplayercontrol',
        component: () => import('../modules/presenter/views/AdminPresenterPlayerView.vue'),
        meta: {
          guards: [usePresenterOptions().moderation ? 'moderation' : 'stage', 'presenterEnabled'],
        },
      },
      {
        path: '/dashboard/support',
        name: 'supportcontrol',
        component: () => import('../modules/support/views/AdminSupportView.vue'),
        meta: {
          guards: [useSupportOptions().moderation ? 'moderation' : 'stage', 'supportEnabled'],
        },
      },
      {
        path: '/dashboard/actions',
        name: 'actionscontrol',
        component: () => import('../modules/actions/views/AdminActionView.vue'),
        meta: {
          guards: [useActionOptions().moderation ? 'moderation' : 'stage', 'actionsEnabled'],
        },
      },
      {
        path: '/live/:view',
        name: 'live',
        component: () => import('../modules/live/views/LiveView.vue'),
        meta: {
          guards: ['live', 'liveEnabled'],
        },
      },
      {
        path: '/modpanel',
        name: 'modpanel',
        component: () => import('../modules/modpanel/views/ModPanelView.vue'),
        meta: {
          guards: ['live', 'modPanelEnabled'],
        },
      },
      {
        path: '/cookie',
        name: 'cookie',
        component: () => import('../modules/cookies/views/CookieView.vue'),
        meta: {
          guards: [],
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'view',
        component: () => import('../modules/player/views/MainView.vue'),
        meta: {
          guards: ['authenticated', 'mainViewEnabled'],
        },
      },
    ],
  });

  const guards = {
    authenticated: () => {
      if (hasToken()) {
        loadUser();
      }
      if (authenticated.value && !isNone.value) return true;
      else if (isNone.value) return { name: 'unverified' };
      else return { name: 'login' };
    },
    admin: () => {
      if (isAdmin.value) return true;
      else return { name: 'view' };
    },
    stage: () => {
      if (isStage.value || isAdmin.value) return true;
      else return { name: 'view' };
    },
    moderation: () => {
      if (isModeration.value || isStage.value || isAdmin.value) return true;
      else return { name: 'view' };
    },
    live: () => {
      if (isLiveUser.value || isModeration.value || isStage.value || isAdmin.value) return true;
      else return { name: 'view' };
    },
    guest: () => {
      if (authenticated.value) return { name: 'view' };
      else return true;
    },
    unverified: () => {
      if (isNone.value) return true;
      else if (authenticated.value) return { name: 'view' };
      else return { name: 'login' };
    },
    auditEnabled: () => useAuditOptions().enabled,
    actionsEnabled: () => useActionOptions().enabled,
    chatEnabled: () => useChatOptions().enabled,
    feedbackEnabled: () => useFeedbackOptions().enabled,
    questionsEnabled: () => useQuestionOptions().enabled,
    mainViewEnabled: () => usePlayerOptions().enabled,
    loginEnabled: () => useLoginOptions().enabled,
    magicLoginEnabled: () => useLoginOptions().magic,
    interactionsEnabled: () => useInteractionOptions().enabled,
    liveEnabled: () => useLiveOptions().enabled,
    modPanelEnabled: () => useModpanelOptions().enabled,
    presenterEnabled: () => usePresenterOptions().enabled,
    qrCodeEnabled: () => useLoginOptions().qrLogin.enabled,
    supportEnabled: () => useSupportOptions().enabled,
    notesEnabled: () => useNotesOptions().enabled,
    userTagsEnabled: () => useUserTagsOptions().enabled,
  };

  router.beforeEach((to, from) => {
    if (cookieOptions.consentRequired && localStorage.getItem(cookieOptions.consentName) === null) {
      if (to.name !== 'cookie') {
        return { name: 'cookie' };
      }
      return true;
    }
    return loadUser().then(() => {
      if (!('guards' in to.meta)) return;

      for (let i = 0; i < to.meta.guards.length; i++) {
        let guard = to.meta.guards[i];
        let value = '';

        if (guard.indexOf(':') !== -1) {
          [guard, value] = guard.split(/:(.*)/s, 2);
        }

        const result = guards[guard](value, to, from);

        if (result !== true) return result;
      }
    });
  });

  return router;
}
